module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CPAS","short_name":"CPAS","start_url":"/","background_color":"#ffffff","theme_color":"#177d7f","display":"standalone","icon":"./src/assets/images/cpas-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"36085cdacc667328d9a7f9a80c938ef5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src3884606952/src/cpas-web"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
